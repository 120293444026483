<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Просмотр жалобы</strong>
    </div>
    <div class="card">
      <div class="card-body">
        <a-form-model>
          <a-form-model-item label="Уникальный ID объявления">
            <a-input v-model="uniqId" read-only/>
          </a-form-model-item>
          <a-form-model-item label="Пользователь">
            <a-input :value="user" read-only/>
          </a-form-model-item>
          <a-form-model-item label="Текст жалобы">
            <a-input :value="complaint.text" read-only />
          </a-form-model-item>
          <a-form-model-item label="Статус">
            <a-input :value="usability[complaint.status]" read-only/>
          </a-form-model-item>
          <a-form-model-item label="Дата">
            <a-input :value="complaint.created_at | getFormattedDate" read-only/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import usability from '@/entities/usability'

export default {
  name: 'id',

  data: () => ({
    usability,
    complaint: {
      text: '',
      status: null,
      created_at: '',
      user: {
        full_name: '',
      },
      product: {
        unique_id: '',
      },
    },
  }),

  async created() {
    this.complaint = (await this.$services.get(`admin/complaints/${this.$route.params.id}`)).data.data.complaint
  },

  computed: {
    user() {
      return this.complaint.user && this.complaint.user.full_name ? this.complaint.user.full_name : ''
    },

    uniqId() {
      return this.complaint.product && this.complaint.product.unique_id ? this.complaint.product.unique_id : ''
    },
  },
}
</script>
